<template>
  <div class="chips-input-container">
    <div class="chips-wrapper">
      <span
        v-for="(chip, index) in chips"
        :key="index"
        class="chip"
      >
        {{ chip }}
        <span @click="removeChip(index)" class="close">&times;</span>
      </span>
      <input
        v-model="newChip"
        @keyup.enter="addChip"
        @keydown.backspace="deleteLastChip"
        placeholder="Enter value (000-000-0000)"
        class="chips-input"
      />
    </div>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newChip: '',
      error: ''
    };
  },
  computed: {
    chips: {
      get() {
        return this.value ? this.value.split(',') : []
      },
      set(newChips) {
        this.$emit('input', newChips.join(','))
      }
    }
  },
  methods: {
    addChip() {
      const regex = /^\d{3}-\d{3}-\d{4}$/
      if (regex.test(this.newChip)) {
        this.chips.push(this.newChip)
        this.newChip = ''
        this.error = ''
      } else {
        this.error = 'Invalid format. Please enter value in the format 000-000-0000'
      }
    },
    removeChip(index) {
      this.chips.splice(index, 1); // Удаление чипа по индексу
      this.$set(this, 'chips', this.chips); // Обновляем массив с использованием $set
    },
    deleteLastChip(event) {
      if (this.newChip === '' && event.key === 'Backspace' && this.chips.length) {
        this.removeChip(this.chips.length - 1)
      }
    }
  }
};
</script>

<style scoped>
.chips-input-container {
  display: flex;
  flex-direction: column;
}

.chips-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 4px;
  height: calc(1.5em + 0.75rem + 2px);
}

.chips-input {
  border: none;
  outline: none;
  flex: 1;
  min-width: 200px;
  height: 100%;
}

.chip {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 16px;
  height: 100%;
}

.close {
  margin-left: 10px;
  cursor: pointer;
  color: #ff0000;
}

.error {
  color: #ff0000;
  margin-top: 10px;
}
</style>
