<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="tabs-page container">
      <b-tabs pills>
        <b-tab title="Platform" @click="$navigateTo('lead-center-settings-platform')" :active="$route.name === 'lead-center-settings-platform'">
          <lead-center-additional-settings />
        </b-tab>
        <b-tab title="Forms" @click="$navigateTo('lead-center-settings-forms')" :active="$route.name === 'lead-center-settings-forms'">
          <lead-center-forms />
        </b-tab>
        <b-tab title="Applications" @click="$navigateTo('lead-center-settings-applications')" :active="$route.name === 'lead-center-settings-applications'">
          <lead-center-applications />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import LeadCenterForms from '@/agGridV2/components/lead-center-forms.vue'
import LeadCenterApplications from '@/agGridV2/components/lead-center-applications.vue'
import LeadCenterAdditionalSettings from '@/agGridV2/components/lead-center-additional-settings.vue'

// Components
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'

export default {
  data() {
    return {}
  },
  components: {
    LeadCenterApplications,
    LeadCenterForms,
    LeadCenterAdditionalSettings,
    LeadCenterNavigation
  }
}
</script>

<style lang="scss">
</style>
