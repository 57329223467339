<template>
  <div class="sync-archived-popup">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row class="pb-2">
        <b-col class="d-flex align-items-center" sm="2">
          <label class="mb-0" :for="`input-year`">Year:</label>
        </b-col>
        <b-col sm="10">
          <b-form-select
            class="p-2"
            style="border: 1px solid grey"
            v-model="year"
            :options="yearOptions"
          >
          </b-form-select>
        </b-col>
      </b-row>
      <div class="actions" style="display: flex; gap: 20px; justify-content: end">
        <div style="display: flex; justify-content: end; margin-top: 10px;">
          <b-btn
            variant="primary"
            :class="['border-0 border-bottom rounded-0', !year ? 'disabled' : '']"
            @click="sync"
          >Sync</b-btn>
        </div>
        <div style="display: none; justify-content: end; margin-top: 10px;">
          <b-btn
            variant="primary"
            class="border-0 border-bottom rounded-0 disabled"
          >Background Process</b-btn>
        </div>
      </div>
      <div class="pt-2" v-if="response">
        <pre>{{ formattedResponse }}</pre>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { showSnackbar } from '@/services'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      year: null,
      response: null,
      application_key: null,
      yearOptions: []
    }
  },
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    },
    formattedResponse() {
      return JSON.stringify(this.response, null, 2)
    }
  },
  mounted() {
    this.application_key = this.$attrs.application_key
    const currentYear = new Date().getFullYear()
    const yearsArray = []
    for (let year = 2021; year <= currentYear; year++) {
      yearsArray.push(year)
    }
    this.yearOptions = yearsArray
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform'
    ]),
    async sync() {
      if (!this.year) {
        showSnackbar({
          text: 'Year Required',
          mode: 'error'
        })
        return false
      }
      try {
        this.loading = true
        this.response = await MtApi.applicationSyncArchived({
          application_key: this.application_key,
          parameters: {
            platform_id: this.selectedPlatform._id,
            archived: true,
            year: this.year
          }
        })
        this.loading = false
      } catch (err) {
        showSnackbar({
          text: err.message,
          mode: 'error'
        })
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sync-archived-popup {
  padding: 20px;
}
</style>
