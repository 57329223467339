<template>
  <div>
    <switches v-model="checked" :emit-on-mount="false" theme="bulma" color="blue" v-on:input="onChange" :disabled="disabled"></switches>
    <div v-if="description" class="pl-2 d-inline">{{ description }}</div>
  </div>
</template>

<script>
import Switches from 'vue-switches'

export default {
  components: {
    Switches
  },
  data() {
    return {
      checked: false,
      disabled: false,
      description: ''
    }
  },
  mounted() {
    if (this.params.disabledEnabled && this.params.data.sub_invoices && this.params.data.sub_invoices.length > 0) {
      this.disabled = true
    }
    if (this.params.value === true) {
      this.checked = true
    }
    if (this.params.disable_for_id) {
      if (!this.params.data.id) {
        this.disabled = true
        this.description = 'Not Valid. Please check ID and update forms'
      }
    }
  },
  methods: {
    onChange(checked) {
      this.checked = checked
      this.params.setValue(this.checked)
    }
  }
}
</script>
