<template>
  <div v-if="platform_applications" class="plugins">
    <div v-for="(application, index) in applications" :key="index">
      <b-overlay
        :show="saving_applications[application.key] && saving_applications[application.key]['saving']"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <b-icon class="mb-4" variant="primary" icon="stopwatch" font-scale="5" animation="cylon"></b-icon>
            <p id="cancel-label">Please wait... Data is verified</p>
          </div>
        </template>
        <b-card class="mb-3 bg-white rounded position-relative">
          <p>
            <strong class="mb-2">{{ application.value.label }}</strong><span v-if="platform_applications[application.key]['lastSync']"> (Last Sync: {{ platform_applications[application.key]['lastSync'] }})</span>
          </p>
          <div v-for="(value, key, setting_index) in application.value.settings" :key="setting_index">
            <b-row class="pb-2" v-if="value.type === 'Chips'">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0" :for="`input-${application.key}-${key}`">{{ value.label }}:</label>
              </b-col>
              <b-col sm="10">
                <chip-input v-model="platform_applications[application.key][key]" />
              </b-col>
            </b-row>
            <b-row class="pb-2" v-if="value.type === 'Outh2' && findOuth2Data(application.key)">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0" :for="`input-${application.key}-${key}`">{{ value.label }}:</label>
              </b-col>
              <b-col sm="10">
                <b-btn
                  variant="primary"
                  class="border-0 border-bottom rounded-0"
                  @click="generateOuth2Link(value.link)"
                >{{ value.label }}</b-btn>
              </b-col>
            </b-row>
            <b-row class="pb-2" v-if="value.type === 'Boolean'">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0" :for="`input-${application.key}-${key}`">{{ value.label }}:</label>
              </b-col>
              <b-col sm="10">
                <b-form-checkbox
                  v-if="platform_applications[application.key]"
                  :id="`input-${application.key}-${key}`"
                  class="active-checkbox"
                  name="check-button"
                  v-model="platform_applications[application.key][key]"
                  switch>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="pb-2" v-if="value.type === 'String'">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0" :for="`input-${application.key}-${key}`">{{ value.label }}:</label>
              </b-col>
              <b-col sm="10">
                <b-form-input
                  v-if="platform_applications[application.key]"
                  :id="`input-${application.key}-${key}`"
                  style="border: 1px solid grey"
                  class="p-2"
                  v-model="platform_applications[application.key][key]"
                  type="text">
                </b-form-input>
                <div class="input-description" style="padding: 2px 5px; font-size: 10px" v-if="value.description">{{ value.description }}</div>
              </b-col>
            </b-row>
            <b-row class="pb-2" v-if="value.type === 'Number'">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0" :for="`input-${application.key}-${key}`">{{ value.label }}:</label>
              </b-col>
              <b-col sm="10">
                <b-form-input
                  v-if="platform_applications[application.key]"
                  :id="`input-${application.key}-${key}`"
                  style="border: 1px solid grey"
                  class="p-2"
                  v-model="platform_applications[application.key][key]"
                  type="number">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row class="pb-2" v-if="value.type === 'HubspotFilters'">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0">{{ value.label }}:</label>
              </b-col>
              <b-col sm="10">
                <div v-if="platform_applications[application.key] && platform_applications[application.key][key]">
                  <div v-for="(filter_value, filter_key, filter_index) in platform_applications[application.key][key]" :key="filter_index">
                    <b-row class="pb-2" style="display: flex; align-items: end">
                      <b-col>
                        <label class="mb-1">Property Name:</label>
                        <b-form-input
                          v-if="platform_applications[application.key]"
                          style="border: 1px solid grey"
                          class="p-2"
                          v-model="platform_applications[application.key][key][filter_key]['propertyName']"
                          type="text">
                        </b-form-input>
                      </b-col>
                      <b-col>
                        <label class="mb-1">Operator:</label>
                        <b-form-select
                          v-if="platform_applications[application.key]"
                          class="p-2"
                          style="border: 1px solid grey;"
                          v-model="platform_applications[application.key][key][filter_key]['operator']"
                          :options="['EQ', 'NEQ']"
                        >
                        </b-form-select>
                      </b-col>
                      <b-col>
                        <label class="mb-1">Value:</label>
                        <b-form-input
                          v-if="platform_applications[application.key]"
                          style="border: 1px solid grey"
                          class="p-2"
                          v-model="platform_applications[application.key][key][filter_key]['value']"
                          type="text">
                        </b-form-input>
                      </b-col>
                      <div style="display: flex; align-items: center; height: 37px; margin-right: 20px; cursor: pointer;">
                        <b-icon variant="primary" icon="trash" @click="deleteFilter(application.key, key, filter_key)"></b-icon>
                      </div>
                    </b-row>
                  </div>
                </div>
                <b-btn
                  variant="primary"
                  class="border-0 border-bottom rounded-0"
                  @click="addFilter(application.key, key)"
                >Add</b-btn>
              </b-col>
            </b-row>
          </div>
          <div v-if="application.key === 'google_analytics'" class="clipboard">
            Service Account Email: <span @click="clipboard">lead-center@management-tools-416909.iam.gserviceaccount.com</span>
          </div>
          <div v-else-if="application.value.documentation" class="documentation">
            <a :href="application.value.documentation" target="_blank">Documentation</a>
          </div>
          <div class="actions" style="display: flex; gap: 20px; justify-content: end">
            <div style="display: flex; justify-content: end; margin-top: 10px;" v-if="application.value.settings.syncArchived && platform_applications[application.key]['active']">
              <b-btn
                variant="primary"
                class="border-0 border-bottom rounded-0"
                @click="syncArchived(application.key)"
              >Sync Archived</b-btn>
            </div>
            <div style="display: flex; justify-content: end; margin-top: 10px;">
              <b-btn
                variant="primary"
                class="border-0 border-bottom rounded-0"
                @click="saveSettings(application.key)"
              >Save</b-btn>
            </div>
          </div>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import SyncArchivedPopup from '@/agGridV2/components/sync-archived-popup.component.vue'

// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters } from 'vuex'
import { showSnackbar } from '@/services'
import ChipInput from '@/agGridV2/components/chips.component'

export default {
  components: {
    ChipInput
  },
  name: 'lead-center-applications',
  data() {
    return {
      applications: [],
      platform_applications: {},
      saving_applications: {}
    }
  },
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  watch: {
    selectedPlatform() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform'
    ]),
    init() {
      (async () => {
        const [settings, configs] = await Promise.all([
          MtApi.getPlatform(this.selectedPlatform._id, { select: 'applications' }),
          MtApi.applicationsConfigs()
        ])
        this.applications = configs
        this.platform_applications = settings.applications || {}
        if (this.applications && this.applications.length > 0) {
          for (const application of this.applications) {
            if (!this.platform_applications[application.key]) {
              this.platform_applications[application.key] = {}
            }
            for (const setting in application['settings']) {
              if (!this.platform_applications[application.key][setting]) {
                this.platform_applications[application.key][setting] = null
              }
            }
          }
        }
      })()
    },
    clipboard(event) {
      navigator.clipboard.writeText(event.target.innerText)
      showSnackbar({
        text: 'Copied!',
        mode: 'success'
      })
    },
    updateSaving(key, value) {
      if (!this.saving_applications[key]) {
        this.saving_applications[key] = {}
      }
      this.saving_applications[key]['saving'] = value
      this.saving_applications = { ...this.saving_applications }
    },
    async saveSettings(key) {
      try {
        this.updateSaving(key, true)
        const settings = this.platform_applications[key]
        const check = await MtApi.checkApplication({
          application_key: key,
          parameters: {
            ...settings,
            platformId: this.selectedPlatform._id
          }
        })
        if (check.error) {
          showSnackbar({
            text: check.message,
            mode: 'error'
          })
          this.platform_applications[key]['active'] = false
          await MtApi.updatePlatform(this.selectedPlatform._id, {
            event: 'update_application',
            field: {
              application_key: key,
              settings: this.platform_applications[key]
            }
          })
          this.updateSaving(key, false)
          return false
        }
        await MtApi.updatePlatform(this.selectedPlatform._id, {
          event: 'update_application',
          field: {
            application_key: key,
            settings: this.platform_applications[key]
          }
        })
        this.updateSaving(key, false)
        showSnackbar({
          text: 'Saved!',
          mode: 'success'
        })
      } catch (err) {
        this.updateSaving(key, false)
      }
    },
    addFilter(application_key, key) {
      if (!this.platform_applications[application_key][key]) {
        this.$set(this.platform_applications[application_key], key, [])
      }
      this.platform_applications[application_key][key].push({
        propertyName: '',
        operator: '',
        value: ''
      })
      this.platform_applications = { ...this.platform_applications }
    },
    deleteFilter(application_key, key, filter_key) {
      this.platform_applications[application_key][key].splice(filter_key, 1)
    },
    async generateOuth2Link(link) {
      window.location.href = await MtApi.generateOuth2Link(link, { platform_id: this.selectedPlatform._id, redirect_url: window.location.href })
    },
    findOuth2Data(key) {
      const platform_settings = this.platform_applications[key]
      if (platform_settings['outh2Data']) {
        return false
      }
      return true
    },
    async syncArchived(key) {
      this.$modal.show(
        SyncArchivedPopup,
        {
          application_key: key
        }, {
          width: '700px'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.clipboard {
  opacity: 0.5;
  margin-top: 10px;
  span {
    padding: 2px 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  span:hover {
    background-color: #e0e0e0;
  }
}
.documentation {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  padding: 20px 20px;
}
</style>
