<template>
  <div class="forms">
    <b-row class="pb-3">
      <b-col class="flex">
        <b-btn style="border-radius: 0" variant="primary" :disabled="forms.parse_status === 'parsing'" @click="parseDomain(false)">Update Forms</b-btn>
        <div class="ml-2" v-show="forms.parse_status"><strong>Status: </strong>{{ forms.parse_status }}</div>
        <b-spinner class="ml-2" variant="primary" v-show="forms.parse_status && forms.parse_status !== 'completed' && forms.parse_status !== 'failed'"></b-spinner>
      </b-col>
    </b-row>
    <ag-grid-vue
      v-show="forms.items"
      class="ag-theme-alpine"
      style="width: 100%; height: 500px"
      headerHeight="32"
      rowHeight="32"
      :masterDetail="true"
      :columnDefs="columnDefs"
      :rowData="forms.items"
      :defaultColDef="defaultColDef"
      :detailCellRendererParams="detailCellRendererParams"
      @cell-value-changed="cellValueChanged"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'

// API Init
import { mapGetters } from 'vuex'
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import CheckboxRenderer from '@/agGridV2/renderers/checkbox.renderer.vue'

export default {
  name: 'lead-center-forms',
  data() {
    return {
      item: {},
      columnDefs: [
        {
          field: 'id',
          headerName: 'ID',
          cellRenderer: 'agGroupCellRenderer'
        },
        {
          field: 'class',
          headerName: 'Class'
        },
        {
          field: 'active',
          headerName: 'Active',
          cellRenderer: 'CheckboxRenderer',
          cellRendererParams: {
            disable_for_id: true
          }
        }
      ],
      defaultColDef: {
        resizable: true,
        flex: 1
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              field: 'url',
              headerName: 'Url',
              cellRenderer: (params) => {
                return `<a href="${params.value}" target="_blank">${params.value}</a>`
              }
            }
          ],
          defaultColDef: {
            flex: 1
          }
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.pages)
        }
      },
      forms: {
        parse_status: null,
        items: []
      }
    }
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxRenderer
  },
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  watch: {
    selectedPlatform() {
      this.init()
    }
  },
  mounted() {
    this.init()
    this.$emitter.on('forms_update', async (data) => {
      await this.init()
      this.$bvToast.toast('Forms Update!', {
        title: data.platform_id,
        variant: 'success',
        solid: true
      })
    })
  },
  methods: {
    ...mapGetters(['getSelectedPlatform']),
    init() {
      if (this.selectedPlatform._id === 'all') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$router.push({ name: 'lead-center-activities' })
        return false
      }
      (async () => {
        const settings = await MtApi.getPlatform(this.selectedPlatform._id, { select: 'forms' })
        this.forms = settings.forms
      })()
    },
    async parseDomain(status = null) {
      this.$dialog
        .confirm(`<img style="width: 100%; margin-bottom: 20px;" src="/uncle_sam.webp" />Forms will be updated in the background. You can reload the page`, {
          html: true,
          cancelText: 'Close',
          okText: 'I\'m sure',
          clicksCount: 3,
          backdropClose: true,
          type: 'soft'
        })
        .then(async(dialog) => {
          this.forms.parse_status = status || 'parsing'
          this.forms = { ...this.forms }
          await MtApi.updatePlatform(this.selectedPlatform._id, {
            event: 'update_forms',
            field: {
              name: 'parse_status',
              value: status || 'parsing'
            }
          })
        })
    },
    async cellValueChanged(params) {
      await MtApi.updatePlatform(this.selectedPlatform._id, {
        event: 'update_form',
        field: {
          form_id: params.data.id,
          name: params.colDef.field,
          value: params.value
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.forms {
  &::v-deep {
    .ag-details-grid {
      overflow: hidden;
    }
  }
}
</style>
